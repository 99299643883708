<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <v-flex xs12>
        <v-tabs show-arrows grow dark background-color="grey darken-3" color="yellow darken-4" v-model="tab">
          <v-tab>Diretos - {{ getUnilevels.level_1.length }}</v-tab>
          <v-tab>Nivel 2 - {{ getUnilevels.level_2.length }}</v-tab>
          <v-tab>Nivel 3 - {{ getUnilevels.level_3.length }}</v-tab>
          <v-tab>Nivel 4 - {{ getUnilevels.level_4.length }}</v-tab>
          <v-tab>Nivel 5 - {{ getUnilevels.level_5.length }}</v-tab>
          <v-tab>Nivel 6 - {{ getUnilevels.level_6.length }}</v-tab>
        </v-tabs>
      </v-flex>

            <v-flex v-if="tab === 0" xs12 class="pa-3">
              <v-simple-table dense style="width: 100%;" v-if="getUnilevels.level_1.length > 0" >
                  <template v-slot:default>
                  <thead>
                      <tr>
                          <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                          <th class="fonte">NOME</th>
                          <th class="fonte">DATA DE INGRESSO</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="item in getUnilevels.level_1" :key="item._id">
                          <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                          <td class="font-weight-bold fonte">{{ item.sponsor_name }}</td>
                          <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                      </tr>
                  </tbody>
                  </template>
              </v-simple-table>
              <Empty v-else ></Empty>
            </v-flex>

            <div v-if="tab === 1" class="expande-horizontal">
                <v-flex xs12 class="pa-3">
                    <v-simple-table dense v-if="getUnilevels.level_2.length > 0" >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                                <th class="fonte">NOME</th>
                                <th class="fonte">DATA DE INGRESSO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in getUnilevels.level_2" :key="item._id">
                                <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                                <td class="font-weight-bold fonte">{{ item.sponsor_name }}</td>
                                <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <Empty v-else ></Empty>
                </v-flex>
            </div>

            <div v-if="tab === 2" class="expande-horizontal">
                <v-flex xs12 class="pa-3">
                    <v-simple-table dense v-if="getUnilevels.level_3.length > 0" >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                                <th class="fonte">NOME</th>
                                <th class="fonte">DATA DE INGRESSO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in getUnilevels.level_3" :key="item._id">
                                <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                                <td class="font-weight-bold fonte">{{ item.sponsor_name }}</td>
                                <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <Empty v-else ></Empty>
                </v-flex>
            </div>

            <div v-if="tab === 3" dense class="expande-horizontal">
                <v-flex xs12 class="pa-3">
                    <v-simple-table v-if="getUnilevels.level_4.length > 0" >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                                <th class="fonte">NOME</th>
                                <th class="fonte">DATA DE INGRESSO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in getUnilevels.level_4" :key="item._id">
                                <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                                <td class="font-weight-bold fonte">{{ item.sponsor_name }}</td>
                                <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <Empty v-else ></Empty>
                </v-flex>
            </div>

            <div v-if="tab === 4" dense class="expande-horizontal">
                <v-flex xs12 class="pa-3">
                    <v-simple-table v-if="getUnilevels.level_5.length > 0" >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                                <th class="fonte">NOME</th>
                                <th class="fonte">DATA DE INGRESSO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in getUnilevels.level_5" :key="item._id">
                                <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                                <td class="font-weight-bold fonte">{{ item.sponsor_name }}</td>
                                <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <Empty v-else ></Empty>
                </v-flex>
            </div>
            
            <div v-if="tab === 5" dense class="expande-horizontal">
                <v-flex xs12 class="pa-3">
                    <v-simple-table v-if="getUnilevels.level_6.length > 0" >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></th>
                                <th class="fonte">NOME</th>
                                <th class="fonte">DATA DE INGRESSO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in getUnilevels.level_6" :key="item._id">
                                <td style="width: 20px;"><v-icon :color="$theme.primary">mdi-network-outline</v-icon></td>
                                <td class="font-weight-bold fonte">{{ item.sponsor_name }}</td>
                                <td class="font-weight-bold fonte">{{  $moment(item.created_at).format('DD/MM/YYYY [às] HH:mm') }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <Empty v-else ></Empty>
                </v-flex>
            </div>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      tab:0
    }
  },
  computed: {
    ...mapGetters(["getUnilevels"])
  },
  methods: {
    ...mapActions(["listUnilevels"])
  },
  created() {
    this.listUnilevels();
  }
};
</script>
